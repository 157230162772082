import styled from 'styled-components';
import {
    black,
    blue,
    blueIcon,
    greenBackground,
    yellow,
    red,
    lightGray,
    darkGreen,
    greenPrintDarker,
    greyLight,
    greyBorder,
    white,
    containerWidth,
} from '../../styles/Variables';
import { CommonInput, SectionHeadline, SectionTitle } from '../../styles/Common';
import selectDivider from '../../static/images/landingPage/divider.png';
import selectArrow from '../../static/images/landingPage/arrow_dropdown.png';
import { BsSearch } from 'react-icons/bs';
import { INVISIBLE } from '../../constants/commonConstants';
import { deviceMax } from '../../styles/MediaQuery';

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const CalculatorContainer = styled.div`
    max-width: 620px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 2.625rem auto 0 auto;
    overflow: visible;
    padding: 0 0.5rem;
    @media ${deviceMax.tablet} {
        overflow: hidden;
    }
`;

export const BgContainer = styled.div`
    background-color: #f4f4f4;
    padding: 2rem 0;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        width: 130%;
        bottom: 0;
        background-color: #f4f4f4;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        width: 130%;
        bottom: 0;
        background-color: #f4f4f4;
    }
`;

export const Title = styled.h1`
    color: ${blue};
    font-size: 2.5rem;
    letter-spacing: 0.025em;
    margin: 0 0 2.5rem 0;
    line-height: 3.5rem;
`;

export const Sub = styled.sub`
    color: ${blue};
`;

export const Text = styled.div`
    margin: 4px 0 4px;
    padding: 8px 0 0;
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;

    * {
        margin: 0;
        padding: 0;
        font-size: 0.875rem;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 20px;
    }
`;

export const HeaderText = styled.h1`
    color: ${blue};
    font-size: 2.5em;
    font-weight: 700;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, Arial;
    letter-spacing: 0.025em;
    line-height: 44px;
    margin-bottom: 1rem;
    margin-top: 1.5em;
    word-break: break-word;
    overflow-wrap: break-word;
`;

export const SearchWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 1em 1.3em;
    background-color: ${greenBackground};
    box-sizing: border-box;
    @media ${deviceMax.tablet} {
        justify-content: center;
        align-items: center;
        padding: 1em 0;
    }
`;

export const SearchFrom = styled.form`
    display: flex;
    flex-direction: column;
    position: relative;
    @media ${deviceMax.tablet} {
        margin-bottom: 1em;
    }
`;

interface ProductBoxProps {
    readonly isActive: boolean;
}

export const Select = styled.select`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
        linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%);
    border: 1px solid ${lightGray};
    cursor: pointer;
    padding-left: 0.8rem;
    font-size: 15px;
    width: 285px;
    height: 2.5rem;
    color: ${darkGreen};
    border-radius: 5px;
    appearance: none;
    outline: none;
    margin: 0;
    transition: 0.3s;
    &:hover {
        color: ${greenPrintDarker};
    }
`;

export const ProductBox = styled.div<ProductBoxProps>`
    ${(props) =>
        props.isActive
            ? {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
              }
            : {
                  display: 'none',
              }}
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid ${lightGray};
    border-radius: 3px;
    position: absolute;
    top: 100%;
    z-index: 10;
    cursor: pointer;
`;

export const ProductList = styled.ul`
    cursor: pointer;
    width: 100%;
    margin: 0;
    padding: 0;
`;

export const ProductListOption = styled.li`
    font-size: 0.875rem;
    color: ${darkGreen};
    background-color: white;
    line-height: 3em;
    font-weight: 200;
    padding: 5px 0 5px 1.25rem;
    border-bottom: 1px solid ${lightGray};
    transition: 0.3s;
    &:hover {
        color: ${greenPrintDarker};
        background-color: ${greyLight};
    }
`;

export const InputSearchWithIconWrapper = styled.div`
    @media ${deviceMax.tablet} {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 1em;
        border-top: 1px solid white;
    }
`;

export const FormItem = styled.div`
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    gap: 50px;
    max-width: 600px;
    @media ${deviceMax.tablet} {
        flex-direction: column;
        gap: 8px;
    }
`;

export const Label = styled.label`
    flex: 0 0 170px;
    @media ${deviceMax.tablet} {
        flex: 1;
    }
`;

export const CircleG = styled.span`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    margin: 8px 24px 8px 0 !important;
    background-color: ${greenBackground};
    flex: 0 0 40px;
`;
export const CircleY = styled.span`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    margin: 8px 24px 8px 0 !important;
    background-color: ${yellow};
    flex: 0 0 40px;
`;
export const CircleR = styled.span`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    margin: 8px 24px 8px 0 !important;
    background-color: ${red};
    flex: 0 0 40px;
`;

export const Coloring = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    p {
        margin: 0;
    }
`;

interface ResultWrapperInterface {
    isFixed: boolean;
}

export const ResultsWrapper = styled.div<ResultWrapperInterface>`
    @media ${deviceMax.tablet} {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-top: ${({ isFixed }) => (isFixed ? '1em' : '0.5em')};
        padding-bottom: ${({ isFixed }) => (isFixed ? '0' : '0.5em')};
        border-top: ${({ isFixed }) => (isFixed ? '1px solid white' : 'none')};
        border-left: ${({ isFixed }) => (isFixed ? 'none' : '2px solid white')};
    }

    * {
        color: ${white};
    }
`;

export const ResultsNumber = styled.span`
    font-size: 2.375rem;
    font-weight: 400;
    line-height: 1.55rem;

    @media ${deviceMax.tablet} {
        line-height: 1.15;
    }
`;

export const ResultsTitle = styled.h2`
    font-weight: 700;
    margin: 1rem 0 0.5rem;
    font-size: 16px;
    padding: 0;
`;

export const InputSearchWithIcon = styled.form`
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
`;

export const Input = styled.input`
    box-sizing: border-box;
    border: 1px solid ${lightGray};
    outline: none;
    padding-left: 0.8rem;
    padding-right: 2.5em;
    padding-top: 0.3em;
    border-radius: 5px;
    width: 285px;
    height: 2.5rem;
    line-height: 25px;
`;

export const InputDisabled = styled.input`
    box-sizing: border-box;
    border: 1px solid ${lightGray};
    outline: none;
    padding-left: 0.8rem;
    padding-right: 2.5em;
    padding-top: 0.3em;
    border-radius: 5px;
    width: 285px;
    height: 2.5rem;
    line-height: 25px;
    pointer-events: none;
`;

export const SearchIcon = styled(BsSearch)`
    fill: ${darkGreen};
    position: absolute;
    right: 10px;
    cursor: pointer;
`;

export const SelectDivider = styled.div`
    height: 70%;
    width: 1px;
    background: url(${selectDivider});
    position: absolute;
    right: 2.1em;
`;

export const CardWrapper = styled.div`
    margin-top: 2.5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.25em;
    width: 100%;
    padding-bottom: 5em;
    border-bottom: 1px solid ${greyBorder};
    @media ${deviceMax.laptop} {
        grid-template-columns: 1fr 1fr;
    }
    @media ${deviceMax.tablet} {
        grid-template-columns: 1fr;
    }
`;

export const Card = styled.div`
    background-color: ${greyLight};
    padding: 1.25em;
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

export const CardHeader = styled.h2`
    color: ${black};
    letter-spacing: 0.025em;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 700;
    font-size: 0.9em;
`;

export const CardImageWrapper = styled.div`
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 1.2em;
    margin-bottom: 0.5em;
`;

export const MobileResultWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${greenBackground};
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
`;

export const ResetButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${white};
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 0 20px;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;

    &:focus {
        border: none;
    }

    * {
        line-height: 1rem;
    }
`;

export const RadioBoxContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
`;

export const RadioLabel = styled.label`
    margin-left: 1rem;
    font-size: 0.875rem;
    line-height: 0.875rem;

    div {
        font-size: 0.875rem;
        line-height: 0.875rem;
    }

    a {
        text-decoration: none;
        color: ${blue};
        margin: 0 2px;
    }

    * {
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const FormSectionTitle = styled(SectionTitle)<FormSectionTitleInterface>`
    margin: 0;
    color: ${(props) => (props.redColor ? 'red' : blueIcon)};
    padding-top: ${(props) => (props.redColor ? '1rem' : 'unset')};
    width: ${(props) => (props.redColor ? '100%' : 'unset')};
    display: ${({ display }) => (display === INVISIBLE ? 'none' : 'block')};
`;

interface FormSectionWrapperInterface {
    readonly deleteMarginBottom?: boolean;
}

export const FormSectionWrapper = styled.div<FormSectionWrapperInterface>`
    margin: 0;
    padding: 0;
    margin-bottom: ${(props) => (props.deleteMarginBottom ? '0' : '2.5rem')};
`;

export const InputSectionWrapper = styled.div`
    margin: 0;
    padding: 0;
`;

interface FormSectionTitleInterface {
    readonly redColor?: boolean;
    readonly display?: string;
}

interface ElementDisplayInterface {
    readonly display?: string;
}

export const FormSectionHeadline = styled(SectionHeadline)<ElementDisplayInterface>`
    text-transform: capitalize;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    display: ${({ display }) => (display === INVISIBLE ? 'none' : 'block')};

    span {
        color: #f00;
        font-weight: 700;
    }
`;

export const InputSectionHeadline = styled(FormSectionHeadline)`
    margin: 0;
    padding: 0;

    * {
        margin: 0;
        padding: 0;
    }
`;

export const TextInput = styled(CommonInput)`
    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

export const Description = styled(Text)<ElementDisplayInterface>`
    display: ${({ display }) => (display === INVISIBLE ? 'none' : 'block')};
    margin-top: -10px;
`;
