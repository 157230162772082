import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import ContactGerman from '../components/ContactGerman/ContactGerman';
import Contact from '../components/Contact/Contact';
import Layout from '../components/Layout/Layout';
import Header from '../components/HeaderDynamicPages/Header';
import { staticPageRedirection } from '../utils/staticPagesRedirection';
import { CONTACT_DE_LANGUAGES } from '../constants/sitesLanguagesGroup';
import { ContactPartnerExample } from '../templateObjects/internationalContact';
import Alternate from '../components/Seo/Alternate';
import { prepareAlternateData } from '../utils/prepareAlternateData';
import contactTranslations from '../JSON/StaticSitesTranslations/contactTranslations.json';
import { getSiteName } from '../utils/getSiteName';
import { DE } from '../constants/languageCode';
import SustainabilityCalculator from '../components/SustainabilityCalculator/SustainabilityCalculator';

const CalculatorGermanPage = ({

}) => {
    const { language } = useI18next();
    const { t } = useTranslation();
    const languagesWithUrls = [];

    useEffect(() => {
        staticPageRedirection(language, 'calculator', CONTACT_DE_LANGUAGES);
    }, []);

    return language === DE ? (

            <SustainabilityCalculator />

    ) : (
        <>
            <Alternate languages={languagesWithUrls} />
        </>
    );
};

export default CalculatorGermanPage;

export const query = graphql`
    query ($language: String!) {
        drupal {
            contact_partners {
                data
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
