import * as language_code from './languageCode';

export const PRODUCTS_LANGUAGES = [
    language_code.EN,
    language_code.EN_IN,
    language_code.EN_SG,
    language_code.EN_UK,
    language_code.EN_US,
    language_code.ES_US,
    language_code.EN_AU,
    language_code.ZH_HANS,
];
export const PRODUCTS_DE_LANGUAGES = [language_code.DE, language_code.DE_AT];
export const PRODUCTS_NL_LANGUAGES = [language_code.NL_BE, language_code.NL_NL];
export const PRODUCTS_ES_LANGUAGES = [language_code.ES, language_code.ES_ES];
export const PRODUCTS_FR_LANGUAGES = [language_code.FR_BE, language_code.FR, language_code.FR_FR, language_code.FR_BE];
export const PRODUCTS_IT_LANGUAGES = [language_code.IT];

export const CONTACT_US_LANGAUGES = [
    language_code.EN,
    language_code.EN_IN,
    language_code.EN_SG,
    language_code.EN_UK,
    language_code.EN_US,
    language_code.ES_US,
    language_code.EN_AU,
];
export const CONTACT_LANGAUGES = [
    language_code.FR,
    language_code.FR_FR,
    language_code.NL_NL,
    language_code.NL_BE,
    language_code.FR_BE,
    language_code.ZH_HANS,
];
export const CONTACT_DE_LANGUAGES = [language_code.DE_AT, language_code.DE];
export const CONTACT_IT_LANGUAGES = [language_code.IT];
export const CONTACT_ES_ES_LANGUAGES = [language_code.ES_ES];
export const CONTACT_ES_LANGUAGES = [language_code.ES];

export const INDUSTRIES_LANGUAGES = [
    language_code.EN,
    language_code.EN_IN,
    language_code.EN_SG,
    language_code.EN_UK,
    language_code.EN_US,
    language_code.ES_US,
    language_code.EN_AU,
    language_code.ZH_HANS,
];
export const INDUSTRIES_DE_LANGUAGES = [language_code.DE_AT, language_code.DE];
export const INDUSTRIES_ES_LANGUAGES = [language_code.ES, language_code.ES_ES];
export const INDUSTRIES_FR_LANGUAGES = [language_code.FR, language_code.FR_FR, language_code.FR_BE];
export const INDUSTRIES_IT_LANGUAGES = [language_code.IT];
export const INDUSTRIES_NL_LANGUAGES = [language_code.NL_NL, language_code.NL_BE];

export const SECOND_HAND_MACHINES_EN_LANGUAGES = [
    language_code.EN,
    language_code.EN_AU,
    language_code.EN_IN,
    language_code.EN_SG,
    language_code.EN_UK,
];
export const SECOND_HAND_MACHINES_DE_LANGUAGES = [language_code.DE, language_code.DE_AT];
export const SECOND_HAND_MACHINES_ES_LANGUAGES = [language_code.ES, language_code.ES_ES];
export const SECOND_HAND_MACHINES_FR_LANGUAGES = [language_code.FR, language_code.FR_BE, language_code.FR_FR];
