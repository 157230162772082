import { composeInitialProps, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import calculator from '../../JSON/SustainabilityCalculator/sustainability-calculator.json';
import { getSiteName } from '../../utils/getSiteName';
import Header from '../HeaderDynamicPages/Header';
import Layout from '../Layout/Layout';
import {
    BgContainer,
    CalculatorContainer,
    CircleG,
    CircleR,
    CircleY,
    Coloring,
    FormItem,
    Input,
    InputDisabled,
    Label,
    ResultsTitle,
    Select,
    Sub,
    Text,
    Title,
} from './SustainabilityCalculatorStyle';

const initialData = {
    selectedModel: '',
    selectedSeries: '',
    calculatorData: {
        refrigerant: '',
        capacity_refrigerant: 0,
        gwp: 0,
        bafa: '',
    },
};

const SustainabilityCalculator = () => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const [data, setData] = useState(initialData);
    const [co2, setCo2] = useState(0);

    const {
        selectedModel,
        selectedSeries,
        calculatorData: { capacity_refrigerant: capacity, gwp, refrigerant },
    } = data;
    const { calculatorData } = data;
    const seriesIsFremdfabrikat = selectedSeries === 'Fremdfabrikat';

    const getCO2Value = (capacity, gwp) => {
        let caclC02 = (capacity * gwp) / 1000;
        return Math.round(caclC02 * 1000) / 1000;
    };

    useEffect(() => {
        if (selectedModel && selectedSeries && !seriesIsFremdfabrikat) {
            console.log(selectedSeries, 'selected series');
            console.log(calculator[selectedModel], 'json data');
            console.log(calculator?.[selectedModel]?.[selectedSeries]), 'selected model from json data';

            setData({
                ...data,
                calculatorData: {
                    ...calculator?.[selectedModel]?.[selectedSeries],
                },
            });
            return;
        }

        if (selectedModel && selectedSeries && refrigerant) {
            const gwp = !seriesIsFremdfabrikat
                ? calculator?.[selectedModel]?.[selectedSeries]?.gwp
                : calculator?.[selectedModel]?.[selectedSeries].refrigerant[refrigerant].gwp;

            setData({
                ...data,
                calculatorData: { ...calculatorData, gwp: gwp, capacity_refrigerant: capacity },
            });
        }
    }, [selectedSeries, selectedModel, refrigerant]);

    useEffect(() => {
        let roundfCo2 = getCO2Value(capacity, capacity?.[capacity]?.gwp);

        if (!seriesIsFremdfabrikat) {
            let roundCo2 = getCO2Value(capacity, gwp);
            setCo2(roundCo2 || 0);
            return;
        }

        setCo2(roundfCo2 || 0);
    }, [calculatorData]);

    const formHandler = (value, name) => {
        let formValue = name === 'selectedCapacity' ? +String(value).replace(',', '.') : value;

        switch (name) {
            case 'selectedModel':
                setData({ ...initialData, [name]: formValue });
                break;

            case 'selectedCapacity':
                setCo2(Math.round(((formValue + gwp) / 1000) * 1000) / 1000);
                setData({ ...data, calculatorData: { ...calculatorData, capacity_refrigerant: formValue } });
                break;

            case 'refrigerant':
                setData({ ...data, calculatorData: { ...calculatorData, refrigerant: formValue } });
                break;

            default:
                setData({ ...data, [name]: formValue });
        }
    };

    const formItem = (func, label, className) => {
        let l = <Label>{label}</Label>;
        if (className === 'co2') {
            const [text, num, ...rest] = label.split('<>');
            l = (
                <Label>
                    {text}
                    <Sub>{num}</Sub> {rest}
                </Label>
            );
        }

        return (
            <FormItem className={className}>
                {l}
                {func}
            </FormItem>
        );
    };

    const formText = (func, text, additionalText) => {
        return (
            <Text>
                <ResultsTitle>{text}</ResultsTitle>
                {func}
                {additionalText && <div>{additionalText}</div>}
            </Text>
        );
    };

    const formSelect = (name, obj) => {
        return (
            <Select name={name} onChange={({ target: { value, name } }) => formHandler(value, name)}>
                <option value="">Bitte auswahlen</option>
                {Object.keys(obj).map((key, idx) => {
                    return (
                        <option key={idx + key} value={key}>
                            {key}
                        </option>
                    );
                })}
            </Select>
        );
    };

    const renderModelHandler = () =>
        !selectedModel ? (
            <p>Bitte auswählen Trockenbaureihe</p>
        ) : (
            formSelect('selectedSeries', calculator[selectedModel])
        );

    const renderCo2TextHandler = () => {
        if (co2 <= 9.9) {
            return (
                <div>
                    Die Prüfpflicht entfällt, da der Kältemittelkreislauf hermetisch geschlossen ist und das
                    CO2-Äquivalent unter 10 t liegt.
                </div>
            );
        }
        if (co2 > 10 && co2 < 49) {
            return <div>Mindestens alle 12 Monate ist eine Dichtigkeitskontrolle erforderlich.</div>;
        }

        if (co2 > 50 && co2 < 499) {
            return <div>Mindestens alle 6 Monate ist eine Dichtigkeitskontrolle erforderlich.</div>;
        }

        if (co2 > 500 && co2 < 50000) {
            return <div>Mindestens alle 3 Monate ist eine Dichtigkeitskontrolle erforderlich.</div>;
        }
    };

    const renderCO2 = () => {
        if (seriesIsFremdfabrikat) {
            let value = (capacity * gwp) / 1000;
            let co2Value = value || 0;
            return (
                <InputDisabled value={String(co2Value.toFixed(3)).replace('.', ',')} defaultValue={0}></InputDisabled>
            );
        } else {
            return <InputDisabled value={String(co2).replace('.', ',')} defaultValue={0}></InputDisabled>;
        }
    };

    const renderRefrigerantHandler = () => {
        if (!(seriesIsFremdfabrikat && selectedModel === 'Fremdfabrikat')) {
            return <InputDisabled value={refrigerant || 0} defaultValue={0}></InputDisabled>;
        }

        const selectedRefrigerant = calculator[selectedModel]?.[selectedSeries]?.refrigerant;
        return formSelect('refrigerant', selectedRefrigerant);
    };

    const renderCapacityHandler = () => {
        return seriesIsFremdfabrikat ? (
            <Input
                name="selectedCapacity"
                onChange={({ target: { value, name } }) => formHandler(value, name)}
                defaultValue={0}
            ></Input>
        ) : (
            <InputDisabled value={capacity || 0} defaultValue={0}></InputDisabled>
        );
    };

    const renderGWPValueHandler = () => <InputDisabled value={gwp || 0} defaultValue={0}></InputDisabled>;

    const renderGWPText = (circle, text) => {
        return (
            <Coloring>
                <p>{circle}</p>
                {text}
            </Coloring>
        );
    };

    const renderGWPTextHandler = () => {
        if (!refrigerant) return;

        if (gwp <= 750) {
            return renderGWPText(
                <CircleG />,
                'Zukunftssicher: Durch den geringen GWP Wert des Kältemittels sind Sie zukunftssicher aufgestellt'
            );
        }
        if (gwp > 750 && gwp < 2500) {
            return renderGWPText(
                <CircleY />,
                ' Unsicher: Der erhöhte GWP Wert des Kältemittels kann in den nächsten Jahren einer neuen EU-Verordnung unterliegen'
            );
        }

        if (gwp >= 2500) {
            return renderGWPText(
                <CircleR />,
                'Da der GWP Wert des Kältemittels über 2500 liegt, ist das Inverkehrbringen seit dem 01.01.2020 untersagt'
            );
        }
    };

    const displayForm = () => {
        return (
            <div>
                <FormItem className="drySeries">
                    <Label>Trockenbaureihe:</Label>
                    <Select name="selectedModel" onChange={({ target: { value, name } }) => formHandler(value, name)}>
                        <option value="">Bitte auswahlen</option>
                        <option value="DS">DS</option>
                        <option value="DH">DH</option>
                        <option value="DT">DT</option>
                        <option value="Fremdfabrikat">Fremdfabrikat</option>
                    </Select>
                </FormItem>
                {formItem(renderModelHandler(), 'Trocknermodell:', 'drySeries')}
                {formItem(renderRefrigerantHandler(), 'Kältemittel:', 'refrigerant')}
                {formItem(renderCapacityHandler(), 'Füllmenge (kg):', 'capacity')}

                <BgContainer>
                    {formItem(renderGWPValueHandler(), 'Treibhauspotential (GWP):', 'gwp')}
                    {formItem(renderCO2(), 'CO<>2<> Äquivalent (t):', 'co2')}
                    {formText(renderCo2TextHandler(), 'Häufigkeit der Dichtigkeitskontrolle ', '')}
                    {formText(renderGWPTextHandler(), 'Zukunftsfähigkeit des Kältemittels', '')}
                    {formText(
                        false,
                        'BAFA-Fähig',
                        'Der Trockner ist in Kombination mit einem entsprechenden Kompressor BAFA-förderfähig'
                    )}
                </BgContainer>
            </div>
        );
    };

    return (
        <Layout title={`${getSiteName(language)} | ${t('contact')}`}>
            <Header item={t('contact')} />
            <CalculatorContainer>
                <Title>
                    BOGE CO<Sub>2</Sub> Nachhaltigkeitsrechner
                </Title>
                <Text>
                    <p>
                        Die F-Gase Verordnung EU 517/2014 soll die Emissionen fluorierter Treibhausgase mindern und so
                        zur Begrenzung der Klimaerwärmung beitragen. Sie ist in Europa verbindliches Recht. Sie
                        verbietet das Inverkehrbringen bestimmter Treibhausgase und Kälteanlagen und sorgt zudem über
                        ein Quotensystem für eine Verknappung des Angebots makrtüblicher Kältemittel. Sie schafft somit
                        u.a. einen Anreiz zur Nutzung klimafreundlicher Technologien, zur Aufbereitung von Gasen und
                        Dichtheitsmaßnahmen.
                    </p>
                    <h2>
                        Mit Hilfe des BOGE Nachhaltigkeitsrechner können Sie die Zukunftssicherheit Ihrer Anlage
                        überprüfen:
                    </h2>
                </Text>
                <form id="sustainability-calculator">{displayForm()}</form>
            </CalculatorContainer>
        </Layout>
    );
};

export default SustainabilityCalculator;
