import { navigate } from 'gatsby-link';
import deSites from '../JSON/StaticSitesAdresses/deSites.json';
import enSites from '../JSON/StaticSitesAdresses/enSites.json';
import deAtSites from '../JSON/StaticSitesAdresses/deAtSites.json';
import enAuSites from '../JSON/StaticSitesAdresses/enAuSites.json';
import enInSites from '../JSON/StaticSitesAdresses/enInSites.json';
import enSgSites from '../JSON/StaticSitesAdresses/enSgSites.json';
import enUkSites from '../JSON/StaticSitesAdresses/enUkSites.json';
import enUsSites from '../JSON/StaticSitesAdresses/enUsSites.json';
import esEsSites from '../JSON/StaticSitesAdresses/esEsSites.json';
import esSites from '../JSON/StaticSitesAdresses/esSites.json';
import esUsSites from '../JSON/StaticSitesAdresses/esUsSites.json';
import frBeSites from '../JSON/StaticSitesAdresses/frBeSites.json';
import frFrSites from '../JSON/StaticSitesAdresses/frFrSites.json';
import frSites from '../JSON/StaticSitesAdresses/frSites.json';
import itSites from '../JSON/StaticSitesAdresses/itSites.json';
import nlBeSites from '../JSON/StaticSitesAdresses/nlBeSites.json';
import nlSites from '../JSON/StaticSitesAdresses/nlSites.json';
import zhHansSites from '../JSON/StaticSitesAdresses/zhHansSites.json';
import * as language_code from '../constants/languageCode';

export const staticPageRedirection = (language: string, alias: string, languagesArray: Array<string>) => {
    if (!languagesArray.includes(language)) {
        switch (language) {
            case language_code.DE:
                navigate(`/${language_code.DE}/${deSites[alias]}`);
                break;
            case language_code.DE_AT:
                navigate(`/${language_code.DE_AT}/${deAtSites[alias]}`);
                break;
            case language_code.EN:
                navigate(`/${language_code.EN}/${enSites[alias]}`);
                break;
            case language_code.EN_AU:
                navigate(`/${language_code.EN_AU}/${enAuSites[alias]}`);
                break;
            case language_code.EN_IN:
                navigate(`/${language_code.EN_IN}/${enInSites[alias]}`);
                break;
            case language_code.EN_SG:
                navigate(`/${language_code.EN_SG}/${enSgSites[alias]}`);
                break;
            case language_code.EN_UK:
                navigate(`/${language_code.EN_UK}/${enUkSites[alias]}`);
                break;
            case language_code.EN_US:
                navigate(`/${language_code.EN_US}/${enUsSites[alias]}`);
                break;
            case language_code.ES:
                navigate(`/${language_code.ES}/${esSites[alias]}`);
                break;
            case language_code.ES_ES:
                navigate(`/${language_code.ES_ES}/${esEsSites[alias]}`);
                break;
            case language_code.ES_US:
                navigate(`/${language_code.ES_US}/${esUsSites[alias]}`);
                break;
            case language_code.FR:
                navigate(`/${language_code.FR}/${frSites[alias]}`);
                break;
            case language_code.FR_BE:
                navigate(`/${language_code.FR_BE}/${frBeSites[alias]}`);
                break;
            case language_code.FR_FR:
                navigate(`/${language_code.FR_FR}/${frFrSites[alias]}`);
                break;
            case language_code.IT:
                navigate(`/${language_code.IT}/${itSites[alias]}`);
                break;
            case language_code.NL_NL:
                navigate(`/${language_code.NL_NL}/${nlSites[alias]}`);
                break;
            case language_code.NL_BE:
                navigate(`/${language_code.NL_BE}/${nlBeSites[alias]}`);
                break;
            case language_code.ZH_HANS:
                navigate(`/${language_code.ZH_HANS}/${zhHansSites[alias]}`);
                break;
            default:
                navigate(`/${language_code.DE}/${deSites[alias]}`);
                break;
        }
    }
};
